import React from 'react'

export default function About(props) {
  return (
    <>
      <div className={`${props.mode === 'dark' ? 'bg-dark' : ''}`}>

        <div id="about" className='madimi-one-regular text-center content-heading-text'> About me </div>

        <hr className="w-75 m-auto" />

        <section className='container-fluid fs-5 text-center mt-5' data-aos="fade-right" data-aos-duration="2000">
          <div className='row'>

            <div className='col-sm-4'>
              <img className='col-12' data-aos="flip-right" data-aos-duration="3000" src="2.jpg" style={{ width: '60%', height: '75%', objectFit: 'cover', borderRadius: '50%' }} />
            </div>

            <div className='col-sm-4 user-select-none'>
              <p className='text-justify'>
                Hello, I'm <strong>Sugam Neupane,</strong><br />a Computer Science and Engineering graduate from CMR Institute of Technology (CMRIT),
                with a passion for technology and innovation.I'm particularly enthusiastic about the MERN stack, which I've explored extensively through various projects.
                As a computer science student with a strong academic background and a passion for technology, I am looking
                for an entry-level position where I can put my programming skills to use in a real-world setting.<br /><br />
                <b>Let's connect and explore the possibilities together!</b>
              </p>
              <div className='text-center'>
                <a className={`btn btn-lg btn-outline-${props.mode === 'dark' ? 'light' : 'primary'} btn-floating m-1`} href="https://www.facebook.com/ns.neupane09/" target='_blank' rel="noopener noreferrer">
                  <i className="bi-facebook"></i>
                </a>

                <a className={`btn btn-lg btn-outline-${props.mode === 'dark' ? 'light' : 'danger'} btn-floating m-1`} href="https://www.instagram.com/ns_neupane09" target='_blank' rel="noopener noreferrer">
                  <i className="bi-instagram"></i>
                </a>

                <a className={`btn btn-lg btn-outline-${props.mode === 'dark' ? 'light' : 'primary'} btn-floating m-1`} href="https://www.linkedin.com/in/sugamneupane/" target='_blank' rel="noopener noreferrer">
                  <i className="bi-linkedin"></i>
                </a>

                <a className={`btn btn-lg btn-outline-${props.mode === 'dark' ? 'light' : 'dark'} btn-floating m-1`} href="https://github.com/NSugam" target='_blank' rel="noopener noreferrer">
                  <i className="bi-github"></i>
                </a>
              </div>

              <div className='text-center mt-3'>
                <a className={`btn btn-lg btn-${props.mode === 'dark' ? 'light' : 'success'} me-2 mt-2 mb-5`} onClick={props.toggleModal}>Hire me</a>
                <a className={`btn btn-lg btn-outline-${props.mode === 'dark' ? 'light' : 'success'} mt-2 mb-5`} href="#contact">Get in touch!</a>
              </div>
            </div>


            <div className='col-sm-4 text-center'>
              {props.isDesktop &&
              <img src={`https://github-readme-stats.vercel.app/api?username=nsugam&show_icons=true&rank_icon=github&theme=${props.mode === 'dark' ? 'radical' : ''}`}
                alt="nsugam" data-aos="fade-down" data-aos-duration="2000" /> }
              <img className='mt-4 mb-5' src={`https://github-readme-stats.vercel.app/api/top-langs/?username=nsugam&layout=donut&theme=${props.mode === 'dark' ? 'radical' : ''}`}
                alt="nsugam" data-aos="flip-left" data-aos-duration="2000" />
            </div>

          </div>
        </section>

      </div>
    </>
  )
}
