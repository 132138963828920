import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../context/SharedState';
import '../CSS/navbar.css'
import { useLocation } from 'react-router-dom';

export default function Navbar() {
  const states = useContext(Context);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation()


  const toggleMode = () => {
    if (states.mode === 'light') {
      states.switchMode('dark')
      states.showAlert('Dark Mode Enabled', 'red', 2500)
    }
    if (states.mode === 'dark') {
      states.switchMode('light')
      states.showAlert('Light Mode Enabled', '#ffeb3b', 3000)
    }
  }

  useEffect(() => {
    const handleScroll = (props) => {
      if (window.scrollY > 500) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      states.setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToContent = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <>
      <a className={`btn btn-sm btn-${states.mode === 'dark' ? 'secondary' : 'success'} ${isScrolled ? 'm-4' : ''}`} hidden={!isScrolled}
        style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: 1000 }}
        href="#home">
        <span className="material-symbols-outlined mt-1"> keyboard_double_arrow_up </span>
      </a>

      <ul className={`nav d-flex nav-background fixed-top  
      ${isScrolled ?
          'scrolled nav-background-onScroll justify-content-start appearfromTop' :
          'pt-2 justify-content-center m-4'}`}>

        {/* Side menu toggle button  */}
        <button className={`btn btn-${states.mode === 'dark' ? 'secondary' : 'dark'} ${isScrolled ? 'm-4' : ''}`} hidden={!isScrolled} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
          <span className="material-symbols-outlined mt-1">menu</span>
        </button>



        {/* Side menu start */}
        <div className={`offcanvas offcanvas-start mt-3 ms-3 rounded ${isScrolled ? '' : 'invisible'}`}
          style={{
            height: states.isDesktop ? 370 : 410, width: 300,
            background: states.mode === 'dark' ? 'url(https://i.ibb.co/nmSkQTC/background.jpg)' :
              'linear-gradient(45deg, #00bcd4,#ffeb3b)'
          }}
          data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div className={`offcanvas-header ${states.mode === 'light' ? 'text-dark' : 'text-light'}`} style={{ backgroundColor: 'rgba(255,255,255,0.15)' }} data-bs-theme={states.mode}>
            <h5 className="offcanvas-title">Main Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" style={{ color: 'red', borderColor: 'red' }}></button>
          </div>
          <div className="offcanvas-body">
            <ul className='nav flex-column'>
              {!states.isDesktop && <li className="nav-item">
                <a className={`d-block fw-bold btn  ${isScrolled && states.mode === 'light' ? 'offCanvas-lightMode btn-outline-light' : 'offCanvas-DarkMode btn-dark'}`}
                  data-bs-dismiss="offcanvas" onClick={toggleMode}>Switch to {states.mode === 'dark' ? 'Light' : 'Dark'} mode </a>
              </li>}

              <li className="nav-item mt-2 mb-2">
                <a className="button btn" href="https://drive.google.com/file/d/1OfhdfY5wfwfz_mg5Vp6VXge7G-A_q7wt/view?usp=sharing" target='_blank' rel="noopener noreferrer">
                  <span class="button__text">Download Resume</span>
                  <span class="button__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307" data-name="Layer 2" class="svg"><path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path><path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path><path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path></svg></span>
                </a>
              </li>

              <li className="nav-item">
                <button className={`nav-link fw-bold  ${isScrolled && states.mode === 'light' ? 'offCanvas-lightMode' : 'offCanvas-DarkMode'}`}
                  onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} data-bs-dismiss="offcanvas">Home</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link fw-bold ${isScrolled && states.mode === 'light' ? 'offCanvas-lightMode' : 'offCanvas-DarkMode'}`}
                  onClick={() => { scrollToContent('about') }} data-bs-dismiss="offcanvas">About Me</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link fw-bold ${isScrolled && states.mode === 'light' ? 'offCanvas-lightMode' : 'offCanvas-DarkMode'}`}
                  onClick={() => { scrollToContent('projects') }} data-bs-dismiss="offcanvas">My Projects</button>
              </li>
              <li className="nav-item">
                <button className={`nav-link fw-bold ${isScrolled && states.mode === 'light' ? 'offCanvas-lightMode' : 'offCanvas-DarkMode'}`}
                  onClick={() => { scrollToContent('contact') }} data-bs-dismiss="offcanvas">Contact</button>
              </li>

              <div className="dropdown" data-bs-theme="dark">
                <a className={`nav-link fw-bold dropdown-toggle ${isScrolled && states.mode === 'light' ? 'offCanvas-lightMode' : 'offCanvas-DarkMode'}`} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Live Projects
                </a>
                <ul className="dropdown-menu fira-sans-regular">
                  <li><a className="dropdown-item" href="https://bookmyride-frontend.vercel.app/" target='_blank' rel="noopener noreferrer">BookMyRide (MERN)</a></li>
                  <li><a className="dropdown-item" href="https://react-text-utils-psi.vercel.app/" target='_blank' rel="noopener noreferrer">Text Utilities (React)</a></li>
                  <li><a className="dropdown-item" href="https://todo-app-nsugam.vercel.app" target='_blank' rel="noopener noreferrer">TODO-app (MERN)</a></li>
                  <li><a className="dropdown-item" href="https://forwardtechnicalblog.blogspot.com/" target='_blank' rel="noopener noreferrer">My Blog</a></li>
                </ul>
              </div>

            </ul>
          </div>
        </div>
        {/* Side menu end */}

        {location.pathname.includes('/myplans') ?
          <li className="nav-item mb-2">
            <a className={`nav-link nav-mainColor fw-bold d-flex justify-content-center ${isScrolled ? 'offCanvas-lightMode' : ''}`} href="/" hidden={isScrolled}>
              <span class="material-symbols-outlined me-2">
                keyboard_backspace
              </span>
              Back to Homepage</a>
          </li>
          : <>
            <li className={`nav-item ms-2 ${states.isDesktop ? 'ms-auto' : 'ms-2'}`}>
              <a className={`nav-link nav-mainColor fw-bold ${isScrolled ? 'offCanvas-lightMode' : ''}`} href="#about" hidden={isScrolled}>About Me</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link nav-mainColor fw-bold text-decoration-none ${isScrolled ? 'offCanvas-lightMode' : ''}`} aria-current="page" href="#skills" hidden={isScrolled}>Achievements</a>
            </li>

            <li className="nav-item">
              <div className="dropdown" data-bs-theme="dark">
                <a className={`nav-link nav-mainColor fw-bold dropdown-toggle ${isScrolled ? 'offCanvas-lightMode ' : ''}`} href="#" hidden={isScrolled} data-bs-toggle="dropdown" aria-expanded="false">
                  Live Projects
                </a>
                <ul className="dropdown-menu fira-sans-regular">
                  <li><a className="dropdown-item" href="https://bookmyride-frontend.vercel.app/" target='_blank' rel="noopener noreferrer">BookMyRide (MERN)</a></li>
                  <li><a className="dropdown-item" href="https://react-text-utils-psi.vercel.app/" target='_blank' rel="noopener noreferrer">Text Utilities (React)</a></li>
                  <li><a className="dropdown-item" href="https://todo-app-nsugam.vercel.app" target='_blank' rel="noopener noreferrer">TODO-app (MERN)</a></li>
                  <li><a className="dropdown-item" href="https://forwardtechnicalblog.blogspot.com/" target='_blank' rel="noopener noreferrer">My Blog</a></li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <a className={`nav-link nav-mainColor fw-bold ${isScrolled ? 'offCanvas-lightMode' : ''}`} href="#contact" hidden={isScrolled}>Contact</a>
            </li>
          </>
        }


        {/* Right Side nav (ms-auto to move to right) */}
        {states.isDesktop && (
          <li className="nav-item ms-auto me-3">
            <div className="checkbox-wrapper-8">
              <input type="checkbox" id="cb3-8" className="tgl tgl-skewed" onClick={() => { toggleMode() }} defaultChecked={states.mode === 'dark'} />
              <label htmlFor="cb3-8" data-tg-on="DARK" data-tg-off="Lights ON" className="tgl-btn"></label>
            </div>
          </li>
        )}
      </ul>
    </>
  );
}
