import React, { useContext, useEffect } from 'react';
import '../CSS/mainBody.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../context/SharedState';

export default function HireMe() {
    const states = useContext(Context)
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search.includes('myplans')) {
            states.toggleModal()
        }
    }, []);

    const handleClick = async (plan) => {
        navigate(`/myplans/${plan}`)
        if (location.pathname.includes('/myplans')) {
            await states.showAlert(`Switched to ${plan.charAt(0).toUpperCase() + plan.slice(1)} plan. Price Updated`, "yellow", 4000);
        } else {
            await states.showAlert(`${plan.charAt(0).toUpperCase() + plan.slice(1)} plan selected`, "yellow", 4000);
        }

    }

    return (
        <>
            <div className="modal fade" id="exampleModal"
                aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-theme={`${states.mode === 'dark' ? 'dark' : 'light'}`}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ overflowY: 'auto', minHeight: '85vh' }}>
                        <div className="modal-header">
                            <h1 className={`modal-title fs-5 text-${states.mode === 'dark' ? 'light' : 'dark'}`} id="exampleModalLabel">Plans & Pricing</h1>

                            <button type="button" className="btn-close" data-bs-dismiss='modal' aria-label="Close"></button>

                        </div>
                        <div className="modal-body">
                            <p className={`text-center fs-2 fw-bold mb-5 text-${states.mode === 'dark' ? 'light' : 'dark'}`}>
                                Choose your plan<br /> according to your requirement
                            </p>
                            <div className="row row-cols-1 row-cols-md-3 m-3">

                                <div className="col">
                                    <a onClick={() => handleClick('basic')} className='nav-link user-select-none' data-bs-dismiss='modal'>
                                        <div className="card text-center mt-4 border">
                                            <div className="card-body">
                                                <h3 className={`card-title text-${states.mode === 'dark' ? 'secondary' : 'dark'} fw-bold fs-2`}>
                                                    Basic Plan<span>*</span>
                                                </h3>
                                                <hr />
                                                <p className="card-title fs-4">
                                                    <span className="material-symbols-outlined fs-5">currency_rupee</span>5,990 INR
                                                </p>
                                                <p className='text-muted'>per project</p>
                                                <p className="card-text text-start">
                                                    <span className="material-symbols-outlined fs-5 text-success me-2">task_alt</span>Completion Time: 15 days<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code access: Frontend only<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Code Explaination<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Backend Code<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Changes in between<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Support after completion<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Vercel Deployment Support<br />
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="col">
                                    <a onClick={() => handleClick('normal')} className='nav-link user-select-none' data-bs-dismiss='modal'>
                                        <div className="card text-center mt-4 border">
                                            <div className="card-body">
                                                <h3 className="card-title text-success fw-bold fs-2">
                                                    Normal Plan<span>*</span>
                                                </h3>
                                                <hr />
                                                <p className="card-title fs-4">
                                                    <span className="material-symbols-outlined fs-5">currency_rupee</span>9,990 INR
                                                </p>
                                                <p className='text-muted'>per project</p>
                                                <p className="card-text text-start">
                                                    <span className="material-symbols-outlined fs-5 text-success me-2">task_alt</span>Completion Time: 7-10 days<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code access: Both<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code Explaination<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Backend Code<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Changes in between<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Support after completion*<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Vercel Deployment Support<br />
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="col">
                                    <a onClick={() => handleClick('premium')} className='nav-link user-select-none' data-bs-dismiss='modal'>
                                        <div className="card text-center mt-4 border">
                                            <div className="card-body">
                                                <h3 className="card-title text-danger fw-bold fs-2">
                                                    Premium Plan<span>*</span>
                                                </h3>
                                                <hr />
                                                <p className="card-title fs-4">
                                                    <span className="material-symbols-outlined fs-5">currency_rupee</span>14,510 INR
                                                </p>
                                                <p className='text-muted'>per project</p>
                                                <p className="card-text text-start">
                                                    <span className="material-symbols-outlined fs-5 text-success me-2">task_alt</span>Completion Time: 7-10 days<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code access: Both<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code Explaination<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Backend Code<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Changes in between*<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Support after completion*<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Vercel Deployment Support<br />
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={`modal-footer text-muted sticky-bottom bg-${states.mode === 'dark' ? 'dark' : 'light'}`}>
                            * Terms and Conditions apply.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
