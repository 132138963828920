import React, { useState } from 'react'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

export default function Counter(props) {

    const [counterOn, setCounterOn] = useState(false)
    return (
        <>
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                
            <div className={`container user-select-none text-center text-light fs-4 mb-5 ${props.isDesktop && 'border rounded-4'}  ${props.mode === 'dark' ? 'bg-dark' : ''}`}
     style={props.mode !== 'dark' ? { background: 'linear-gradient(45deg, #ffe555, #00bcd4)' } : {}}>

                    <div className="row row-cols-1 row-cols-md-4 fs-4">

                        <div className='col mt-3'>
                            <img src='project_logo.png' style={{ width: '25%', height: '50%' }} alt="Project Logo"/>
                            <p className='fw-bold'>
                                Projects Completed <br />
                                {counterOn && <CountUp start={0} end={11} duration={3} delay={0} />}+
                            </p>
                        </div>

                        <div className='col mt-3'>
                            <img src='working_logo.png' style={{ width: '25%', height: '50%' }} alt="Working Logo"/>
                            <p className='fw-bold'>
                                Working hours <br />
                                {counterOn && <CountUp start={0} end={222} duration={3} delay={0} />}+
                            </p>
                        </div>

                        <div className='col mt-3'>
                            <img src='Client_logo.png' style={{ width: '25%', height: '50%' }} alt="Client Logo"/>
                            <p className='fw-bold'>
                                Happy Clients <br />
                                {counterOn && <CountUp start={0} end={19} duration={3} delay={0} />}+
                            </p>
                        </div>

                        <div className='col mt-3'>
                            <img src='visitor_logo.png' style={{ width: '25%', height: '50%' }} alt="Client Logo"/>
                            <p className='fw-bold'>
                                Profile Visitors <br />
                                {counterOn && <CountUp start={0} end={773} duration={3} delay={0} />}+
                            </p>
                        </div>


                    </div>
                </div>
            </ScrollTrigger>
        </>
    )
}
